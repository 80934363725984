<template>
    <v-card flat class="border-radius-2 card-border" :loading="loading">
        <v-container>
            <v-row class="border-bottom-dashed mx-2">
                <v-col cols="4">
                    <span class="primary--text font-size-14 text-capitalize">{{
                        $t('reporting.sub_skill')
                    }}</span>
                </v-col>
                <v-col cols="2" class="text-center">
                    <span class="primary--text font-size-14 text-capitalize">{{
                        $t('correct')
                    }}</span>
                </v-col>
                <v-col cols="2" class="text-center">
                    <span class="primary--text font-size-14 text-capitalize">{{
                        $t('total')
                    }}</span>
                </v-col>
                <v-col cols="4" class="text-center">
                    <span class="primary--text font-size-14 text-capitalize">{{
                        $t('accuracy')
                    }}</span>
                </v-col>
            </v-row>
            <div v-if="subSkillsData && subSkillsData.length">
                <v-row
                    v-for="(item, index) of subSkillsData"
                    :key="index"
                    :class="`${
                        index !== subSkillsData.length - 1 ? 'border-bottom-dashed' : ''
                    } mx-2 align-center justify-center`"
                >
                    <v-col cols="4">
                        <span class="grey--text font-size-14"
                            >{{ $t(`subSkill.${item.code.replace('.', '_')}`) }}</span
                        >
                    </v-col>
                    <v-col cols="2" class="text-center">
                        <span class="grey--text font-size-12 text-body-2">{{ item.correct }}</span>
                    </v-col>
                    <v-col cols="2" class="text-center">
                        <span class="grey--text font-size-12 text-body-2">
                            {{ item.total }}
                        </span>
                    </v-col>
                    <v-col cols="4" class="text-center">
                        <BeeProgressBar
                            :value="item.accuracy"
                            :progress="item.accuracy"
                            :color="color(item.accuracy)"
                        >
                            <span class="font-weight-light font-size-12">{{
                                `${item.accuracy}%`
                            }}</span>
                        </BeeProgressBar>
                    </v-col>
                </v-row>
            </div>
            <v-row v-else>
                <v-col cols="12" class="text-center">
                    <span class="primary--text font-size-18 text-capitalize">{{
                        $t('not_data')
                    }}</span>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import BeeProgressBar from '../../core/BeeProgressBar'
export default {
    name: 'SubSkillAccuracyTable',
    components: { BeeProgressBar },
    props: {
        subSkillsData: { type: Array, default: () => [] },
        loading: { type: Boolean, default: false },
    },
    methods: {
        color(value = 0) {
            if (value <= 40) return '#514773'
            if (value >= 41 && value <= 59) return '#fddc61'
            return '#24dca9'
        },
    },
}
</script>

<style scoped>
.card-border {
    border: 2px solid #aeb8b3;
}
.border-bottom-dashed {
    border-bottom: #8d8d8d dashed 1px;
}
</style>
