<!-- Deprecated, this component is not being used anyomre - 2023-04-12 -->
<template>
    <v-row cols="12">
        <v-card
            elevation="5"
            class="px-3 pb-15 w-100 border-radius-1 elevation-1"
            outlined
            :loading="loadingData"
        >
            <v-row align="end" class="px-7">
                <v-card-title class="text-h5 font-weight-bold py-1 ma-3">{{
                    $t('reporting.skills')
                }}</v-card-title>
                <v-col class="d-flex ml-auto justify-center align-center pa-0 py-1" cols="2">
                    <v-select
                        :label="$t('choose_course')"
                        class="ml-auto w-50"
                        item-value="id"
                        item-text="name"
                        :items="coursesWithGlobal"
                        v-model="currentClass"
                        dense
                        outlined
                        flat
                        hide-details
                        color="info"
                        @change="getDataByCourse"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row class="px-7">
                <v-col class="mr-2" cols="4">
                    <v-row class="px-0">
                        <v-col cols="12" class="w-75" style="height: 40px;">
                            <BeeTabs
                                @changeTab="changeTab"
                                :items="days"
                                :tabSize="4"
                                :textAlign="'left'"
                                :tabSelected="currentTab"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-row class="d-flex justify-center align-center">
                                <v-col cols="6" @click="setSkillCode(null)" class="clickable"
                                    ><GlobalSummaryCard
                                        :class="`${
                                            currentSkillCode === null ? 'global-card-circle ' : ''
                                        }`"
                                        :title="$t('stats.global_summary')"
                                /></v-col>
                                <v-col cols="6" @click="setSkillCode('LOC')" class="clickable"
                                    ><GraphCircleCard
                                        :class="`${
                                            currentSkillCode === 'LOC' ? 'global-card-circle ' : ''
                                        }`"
                                        :value="
                                            currrentData
                                                ? currrentData.general[2]
                                                    ? currrentData.general[2].percent
                                                    : 0
                                                : 0
                                        "
                                        color="#514773"
                                        :title="$t('stats.locate_information')"
                                /></v-col>
                                <v-col cols="6" @click="setSkillCode('IN')" class="clickable"
                                    ><GraphCircleCard
                                        :class="`${
                                            currentSkillCode === 'IN' ? 'global-card-circle ' : ''
                                        }`"
                                        :value="
                                            currrentData
                                                ? currrentData.general[0]
                                                    ? currrentData.general[0].percent
                                                    : 0
                                                : 0
                                        "
                                        color="#FF051B"
                                        :title="$t('stats.integrate_information')"
                                /></v-col>
                                <v-col cols="6" @click="setSkillCode('RV')" class="clickable"
                                    ><GraphCircleCard
                                        :class="`${
                                            currentSkillCode === 'RV' ? 'global-card-circle ' : ''
                                        }`"
                                        :value="
                                            currrentData
                                                ? currrentData.general[1]
                                                    ? currrentData.general[1].percent
                                                    : 0
                                                : 0
                                        "
                                        color="#0282EB"
                                        :title="$t('stats.reflect_evaluate')"
                                /></v-col>
                            </v-row>
                        </v-col>
                        <v-col>
                            <Events section="skills" />
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="ml-2 ml-auto" cols="7" v-if="currentSkillCode">
                    <SubSkillAccuracyTable :sub-skills-data="skillData" :loading="loadingData" />
                </v-col>
                <v-col class="ml-2 ml-auto" cols="7" v-else>
                    <v-row>
                        <ProgressChart
                            :title="$t('progress')"
                            :color="'#8d8d8d'"
                            :progress-data="currrentData ? currrentData.progress : []"
                        />
                    </v-row>
                    <v-row class="mt-10"
                        ><ReportingPerStudents
                            :title="$t('reporting.students_list')"
                            :color="'#8d8d8d'"
                            :items="getProgressDataFirst(currrentData, 5)"
                            @redirect="redirect"
                    /></v-row>
                </v-col>
            </v-row>
        </v-card>
    </v-row>
</template>

<script>
import { mapState } from 'vuex'
import Events from '@/components/stats/Events.vue'
import GraphCircleCard from './core/GraphCircleCard'
import GlobalSummaryCard from './core/GlobalSummaryCard'
import ReportingPerStudents from './core/ReportingPerStudents'
import ProgressChart from './core/ProgressChart'
import tierStats from '../../services/tierStats'
import BeeTabs from '../core/BeeTabs'
import SubSkillAccuracyTable from './core/SubSkillAccuracyTable'

export default {
    name: 'CardSkill',
    components: {
        BeeTabs,
        GraphCircleCard,
        GlobalSummaryCard,
        Events,
        ReportingPerStudents,
        ProgressChart,
        SubSkillAccuracyTable,
    },
    data: () => ({
        currentClass: 0,
        loadingData: false,
        ninetyDaysData: null,
        thirtyDaysData: null,
        sevenDaysData: null,
        currentTab: 2,
        dataLoaded: false,
        currentSkillCode: null,
        skillninetyDaysData: null,
        skillThirtyDaysData: null,
        skillSevenDaysData: null,
    }),
    computed: {
        ...mapState({
            courses: (state) => state.userData.courses,
            user: (state) => state.userData.user,
        }),
        coursesWithGlobal() {
            return [
                ...[{ id: 0, name: this.$t('global') }],
                ...this.courses.map((i) => ({ id: i.id, name: i.name })),
            ]
        },
        days() {
            return [
                this.$t('stats.seven_days'),
                this.$t('stats.thirty_days'),
                this.$t('stats.ninety_days'),
            ]
        },
        currrentData() {
            switch (this.currentTab) {
                case 0:
                    return this.sevenDaysData
                case 1:
                    return this.thirtyDaysData
                case 2:
                    return this.ninetyDaysData
                default:
                    return null
            }
        },
        skillData() {
            switch (this.currentTab) {
                case 0:
                    return this.skillSevenDaysData
                case 1:
                    return this.skillThirtyDaysData
                case 2:
                    return this.skillninetyDaysData
                default:
                    return []
            }
        },
    },
    async mounted() {
        if (this.currentSkillCode) await this.getSubSkillData()
        await this.getDataByCourse()
    },
    watch: {
        async currentSkillCode() {
            if (this.currentSkillCode) await this.getSubSkillData()
        },
    },
    methods: {
        changeTab(tab) {
            this.currentTab = tab
        },
        getCoursesArray() {
            const result = []
            this.courses.forEach((i) => result.push(i.id))
            return result
        },
        async getSubSkillData() {
            this.loadingData = !this.loadingData
            const response = await tierStats
                .getSubSkillAccuracyByCourseId(
                    this.currentSkillCode,
                    this.currentClass,
                    this.getCoursesArray()
                )
                .catch((e) => console.error(e))
            console.log(response.content)
            const { day7, day30, day90 } = response.content
            this.skillSevenDaysData = day7
            this.skillThirtyDaysData = day30
            this.skillninetyDaysData = day90
            this.loadingData = !this.loadingData
        },
        async getDataByCourse() {
            this.loadingData = !this.loadingData
            const response = await tierStats
                .getProgressSkillByCourseId(this.currentClass, this.user.id)
                .catch((e) => console.error(e))
            const { day7, day30, day90 } = response.content
            console.log({ day7, day30, day90 })
            this.ninetyDaysData = day90
            this.orderData(this.ninetyDaysData.userProgress)
            this.thirtyDaysData = day30
            this.orderData(this.thirtyDaysData.userProgress)
            this.sevenDaysData = day7
            this.orderData(this.sevenDaysData.userProgress)
            this.loadingData = !this.loadingData
        },
        orderData(data = []) {
            data.sort((a, b) => this.compare(a, b))
        },
        compare(a, b) {
            const sumA = ((a.LOC || 0 + a.RV || 0 + a.IN || 0) / 3).toFixed()
            const sumB = ((b.LOC || 0 + b.RV || 0 + b.IN || 0) / 3).toFixed()
            return sumA > sumB ? 1 : sumA < sumB ? -1 : 0
        },
        getProgressDataFirst(data = null, cant = 0) {
            if (!data) return []
            const { userProgress } = data
            return userProgress.slice(0, cant)
        },
        getProgressDataLast(data = null, cant = 0) {
            if (!data) return []
            const { userProgress } = data
            return userProgress.slice(Math.max(userProgress.length - cant, 0))
        },
        redirect() {
            this.$router.push(`top-students/${this.currentClass}`)
        },
        setSkillCode(code = null) {
            this.currentSkillCode = code
        },
    },
}
</script>

<style scoped>
.global-card-circle {
    box-sizing: border-box;
    border: 4px solid #b9b1d5;
    border-radius: 5px;
    box-shadow: -4px 4px 10px 4px rgba(0, 0, 0, 0.04);
}
.clickable{
    cursor: pointer;
}
</style>
