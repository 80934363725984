<template>
    <v-container
        fluid
        class="reporting-table-content position-relative d-flex align-center flex-column"
        :style="`border: 3px solid ${color};`"
    >
        <v-container class="py-0">
            <v-row class="mr-auto">
                <v-col cols="6" :style="{ color }">{{ title }}</v-col>
                <v-col cols="2" class="font-size-12 subtitle-color">{{
                    $t('stats.locate_information')
                }}</v-col>
                <v-col cols="2" class="font-size-12 subtitle-color">{{
                    $t('stats.integrate_information')
                }}</v-col>
                <v-col cols="2" class="font-size-12 subtitle-color">{{
                    $t('stats.reflect_evaluate')
                }}</v-col>
            </v-row>
        </v-container>
        <v-divider />
        <v-container v-if="items.length" class="mr-auto">
            <v-row v-for="(item, index) of items" :key="index" class="align-center">
                <v-col cols="6" class="font-size-14 subtitle-color py-2">
                    <v-row class="pa-0">
                        <v-col
                            cols="3"
                            class="pa-0 d-flex justify-center align-center"
                            align-self="center"
                        >
                            <UserAvatar
                                :user-name="$utils.getFullName(item.user)"
                                :avatar="item.user.avatar"
                                size="4"
                            />
                        </v-col>
                        <v-col cols="9" class="pa-0 d-flex justify-start align-center">
                            <span class="ml-1">{{ $utils.getFullName(item.user) }}</span>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="2" class="font-size-14 subtitle-color py-2">{{
                    `${item.LOC || 0}%`
                }}</v-col>
                <v-col cols="2" class="font-size-14 subtitle-color py-2">{{
                    `${item.IN || 0}%`
                }}</v-col>
                <v-col cols="2" class="font-size-14 subtitle-color py-2">{{
                    `${item.RV || 0}%`
                }}</v-col>
            </v-row>
        </v-container>
        <v-container v-else class="w-100 text-center">
            <span class="text-h6 text-center">{{ $t('not_data') }}</span>
        </v-container>
        <v-btn
            rounded
            outlined
            small
            :style="`border: 3px solid ${color} !important; color: ${color};`"
            class="see-list position-absolute white text-capitalize"
            v-if="items.length"
            @click="redirect"
            >{{ $t('see_full_list') }}</v-btn
        >
    </v-container>
</template>
<script>
import UserAvatar from '../../core/UserAvatar'
export default {
    name: 'ReportingPerStudents',
    components: { UserAvatar },
    props: {
        title: { type: String, default: 'without title', required: true },
        color: { type: String, default: '#000', required: true },
        items: { type: Array, default: [] },
        courseId: { type: [String, Number], default: '' },
    },
    methods: {
        redirect() {
            this.$emit('redirect')
        },
    },
}
</script>

<style scoped>
.reporting-table-content {
    box-sizing: border-box;
    border-radius: 6px;
}
.subtitle-color {
    color: #8d8d8d;
}

.see-list {
    bottom: -2vh;
}
</style>
