<template>
    <div class="w-100 fill-height d-flex justify-center align-center flex-column card-circle pa-4">
        <EllipseProgress
            :value="value"
            :size="size"
            :legend="false"
            :color="color"
        ></EllipseProgress>
        <div class="mt-4 w-80 text-center">
            <span>{{ title }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GraphCircleCard',
    props: {
        value: { type: Number, default: 0 },
        size: { type: Number, default: 100 },
        color: { type: String, default: '#514773' },
        title: { type: String, default: 'no title' },
    },
}
</script>

<style scoped>
.card-circle {
    box-sizing: border-box;
    border: 1px solid #e9e6f0;
    border-radius: 5px;
    box-shadow: -4px 4px 10px 4px rgba(0, 0, 0, 0.04);
}
</style>
