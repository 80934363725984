var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"px-3 pb-15 w-100 border-radius-1 elevation-1",attrs:{"elevation":"5","outlined":"","loading":_vm.loadingData}},[_c('v-row',{staticClass:"px-7",attrs:{"align":"end"}},[_c('v-card-title',{staticClass:"text-h5 font-weight-bold py-1 ma-3"},[_vm._v(_vm._s(_vm.$t('reporting.skills')))]),_c('v-col',{staticClass:"d-flex ml-auto justify-center align-center pa-0 py-1",attrs:{"cols":"2"}},[_c('v-select',{staticClass:"ml-auto w-50",attrs:{"label":_vm.$t('choose_course'),"item-value":"id","item-text":"name","items":_vm.coursesWithGlobal,"dense":"","outlined":"","flat":"","hide-details":"","color":"info"},on:{"change":_vm.getDataByCourse},model:{value:(_vm.currentClass),callback:function ($$v) {_vm.currentClass=$$v},expression:"currentClass"}})],1)],1),_c('v-row',{staticClass:"px-7"},[_c('v-col',{staticClass:"mr-2",attrs:{"cols":"4"}},[_c('v-row',{staticClass:"px-0"},[_c('v-col',{staticClass:"w-75",staticStyle:{"height":"40px"},attrs:{"cols":"12"}},[_c('BeeTabs',{attrs:{"items":_vm.days,"tabSize":4,"textAlign":'left',"tabSelected":_vm.currentTab},on:{"changeTab":_vm.changeTab}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"d-flex justify-center align-center"},[_c('v-col',{staticClass:"clickable",attrs:{"cols":"6"},on:{"click":function($event){return _vm.setSkillCode(null)}}},[_c('GlobalSummaryCard',{class:`${
                                        _vm.currentSkillCode === null ? 'global-card-circle ' : ''
                                    }`,attrs:{"title":_vm.$t('stats.global_summary')}})],1),_c('v-col',{staticClass:"clickable",attrs:{"cols":"6"},on:{"click":function($event){return _vm.setSkillCode('LOC')}}},[_c('GraphCircleCard',{class:`${
                                        _vm.currentSkillCode === 'LOC' ? 'global-card-circle ' : ''
                                    }`,attrs:{"value":_vm.currrentData
                                            ? _vm.currrentData.general[2]
                                                ? _vm.currrentData.general[2].percent
                                                : 0
                                            : 0,"color":"#514773","title":_vm.$t('stats.locate_information')}})],1),_c('v-col',{staticClass:"clickable",attrs:{"cols":"6"},on:{"click":function($event){return _vm.setSkillCode('IN')}}},[_c('GraphCircleCard',{class:`${
                                        _vm.currentSkillCode === 'IN' ? 'global-card-circle ' : ''
                                    }`,attrs:{"value":_vm.currrentData
                                            ? _vm.currrentData.general[0]
                                                ? _vm.currrentData.general[0].percent
                                                : 0
                                            : 0,"color":"#FF051B","title":_vm.$t('stats.integrate_information')}})],1),_c('v-col',{staticClass:"clickable",attrs:{"cols":"6"},on:{"click":function($event){return _vm.setSkillCode('RV')}}},[_c('GraphCircleCard',{class:`${
                                        _vm.currentSkillCode === 'RV' ? 'global-card-circle ' : ''
                                    }`,attrs:{"value":_vm.currrentData
                                            ? _vm.currrentData.general[1]
                                                ? _vm.currrentData.general[1].percent
                                                : 0
                                            : 0,"color":"#0282EB","title":_vm.$t('stats.reflect_evaluate')}})],1)],1)],1),_c('v-col',[_c('Events',{attrs:{"section":"skills"}})],1)],1)],1),(_vm.currentSkillCode)?_c('v-col',{staticClass:"ml-2 ml-auto",attrs:{"cols":"7"}},[_c('SubSkillAccuracyTable',{attrs:{"sub-skills-data":_vm.skillData,"loading":_vm.loadingData}})],1):_c('v-col',{staticClass:"ml-2 ml-auto",attrs:{"cols":"7"}},[_c('v-row',[_c('ProgressChart',{attrs:{"title":_vm.$t('progress'),"color":'#8d8d8d',"progress-data":_vm.currrentData ? _vm.currrentData.progress : []}})],1),_c('v-row',{staticClass:"mt-10"},[_c('ReportingPerStudents',{attrs:{"title":_vm.$t('reporting.students_list'),"color":'#8d8d8d',"items":_vm.getProgressDataFirst(_vm.currrentData, 5)},on:{"redirect":_vm.redirect}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }