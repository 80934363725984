<template>
    <v-container
        fluid
        class="reporting-table-content position-relative d-flex align-center flex-column"
        :style="`border: 3px solid rgb(141 141 141 / 20%);`"
    >
        <v-container class="py-0 mr-auto">
            <v-row class="d-flex flex-row">
                <v-col :style="{ color }" class="mr-auto">{{ title }}</v-col>
                <v-col :style="{ color }" class="ml-auto text-right text-capitalize">
                    {{ $moment().format('MMMM, YYYY') }}
                </v-col>
            </v-row>
        </v-container>
        <v-divider />
        <v-container style="border-top: 1px solid whitesmoke;">
            <v-col cols="12" class="d-flex justify-center" v-if="showChart">
                {{ $t('stats.press_legen_item_preview') }}
            </v-col>
            <v-col cols="12" class="d-flex justify-center" v-if="showChart">
                <v-chart :options="polar" width="100%" />
            </v-col>
        </v-container>
    </v-container>
</template>
<script>
const defaultArray = [0, 0, 0, 0, 0, 0, 0]
const areaStyleFn = (color) => ({
    color: {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [
            {
                offset: 0,
                color,
            },
            {
                offset: 1,
                color: 'white',
            },
        ],
        global: false,
    },
});
const lineStyleFn = ({ color, width }) => ({
    color,
    width,
});

export default {
    name: 'ProgressChart',
    props: {
        title: { type: String, default: 'without title', required: true },
        color: { type: String, default: '#000', required: true },
        progressData: { type: Array, default: () => [] },
    },
    mounted() {
        if (this.progressData) {
            this.setData()
        } else {
            this.setDefaultData()
        }
    },
    methods: {
        setData() {
            this.showChart = false
            const result = this.getAllData()
            this.polar.xAxis.data = result.series
            this.polar.series[0].data = result.loc
            this.polar.series[1].data = result.iN
            this.polar.series[2].data = result.rv
            this.showChart = !this.showChart
        },
        setDefaultData() {
            this.showChart = false
            this.polar.xAxis.data = this.$t('days_names')
            this.polar.series[0].data = defaultArray
            this.polar.series[1].data = defaultArray
            this.polar.series[2].data = defaultArray
            this.showChart = !this.showChart
        },
        getSeriesValue(arr = []) {
            const result = []
            const vm = this

            // Creates array with unique dates
            arr.forEach(({ appliedDate }) => {
                if (!result.some((r) => r === vm.$moment(appliedDate).format('YYYY-MM-DD'))) {
                    result.push(vm.$moment(appliedDate).format('YYYY-MM-DD'))
                }
            })
            result.sort(
                (a, b) => vm.$moment(a).toDate() - vm.$moment(b).toDate()
            )

            // Formats dates array to "day. month name"
            return result.map((i) => vm.$moment(i).format('DD. MMM'))
        },
        getAllData() {
            const series = this.getSeriesValue(this.progressData)
            const rv = this.getData(this.filterByCode(this.progressData, 'RV'), series)
            const loc = this.getData(this.filterByCode(this.progressData), series)
            const iN = this.getData(this.filterByCode(this.progressData, 'IN'), series)
            return { series, rv, iN, loc }
        },
        getData(arr = [], serieArr = []) {
            const result = []
            const vm = this
            if (!arr.length) return defaultArray

            serieArr.forEach((i) => {
                const found = arr.find(
                    ({ appliedDate }) => i === vm.$moment(appliedDate).format('DD. MMM')
                )
                if (found) result.push(found.percent)
                // Puts null value to connect the dots on the graph without data
                else result.push(null)
            })

            return result
        },
        filterByCode(arr = [], code = 'LOC') {
            return arr.filter((i) => i.code === code)
        },
    },
    watch: {
        progressData(newValue) {
            this.setData()
        },
    },
    data: () => ({
		showChart: false,
		polar: {
            legend: {
                data: ['LOC', 'IN', 'RV'],
            },
			tooltip: {
				trigger: 'axis',
                backgroundColor: 'white',
                axisPointer: {
                    type: 'shadow',
                },
                textStyle: {
                   color: 'black'
                }
			},
			xAxis: {
				type: 'category',
				data: [],
				axisLine: {
					show: false,
				},
				axisTick: {
					show: false,
				},
				boundaryGap: false,
			},
			yAxis: {
				type: 'value',
				axisLabel: {
					formatter: '{value}%',
				},
				nameLocation: 'middle',
				nameGap: 50,
				axisLine: {
					show: false,
				},
				axisTick: {
					show: false,
				},
				splitLine: {
					show: false,
				},
			},
			series: [
				{
					data: [],
                    connectNulls: true,
					type: 'line',
					color: '#7055d0',
					smooth: true,
                    name: 'LOC',
					areaStyle: areaStyleFn('#7055d0'),
					lineStyle: lineStyleFn({ color: '#7055d0', width: 2 }),
				},
				{
					data: [],
                    connectNulls: true,
					type: 'line',
					color: '#FF051B',
					smooth: true,
                    name: 'IN',
					areaStyle: areaStyleFn('#FF051B'),
					lineStyle: lineStyleFn({ color: '#FF051B', width: 2 }),
				},
				{
					data: [],
                    connectNulls: true,
					type: 'line',
					color: '#0282EB',
					smooth: true,
                    name: 'RV',
					areaStyle: areaStyleFn('#0282EB'),
					lineStyle: lineStyleFn({ color: '#0282EB', width: 2 }),
				},
			],
		},
	}),
}
</script>

<style scoped>
.reporting-table-content {
    box-sizing: border-box;
    border-radius: 6px;
}
</style>
