<template>
    <div class="card-circle w-100 fill-height d-flex justify-center align-center flex-column pa-4">
        <div class="spinner"></div>
        <div class="mt-4 w-75 text-center">
            <span>{{ title }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GlobalSummaryCard',
    props: {
        title: { type: String, default: 'no title' },
    },
}
</script>

<style scoped>
.spinner,
.spinner:after {
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
}
.spinner {
    background-color: transparent;
    border-top: 10px solid #6540ed;
    border-right: 10px solid #ff384a;
    border-bottom: 10px solid #7ec2ff;
    border-left: 10px solid #f1c044;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-duration: 2s;
    animation-name: spinner-loading;
}

@keyframes spinner-loading {
    0% {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(1turn);
    }
}

.card-circle {
    box-sizing: border-box;
    border: 1px solid #e9e6f0;
    border-radius: 5px;
    box-shadow: -4px 4px 10px 4px rgba(0, 0, 0, 0.04);
}
</style>
